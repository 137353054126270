<template>
    <div class="tempmain">
        <van-nav-bar
            title="群模板"
            left-text="返回"
            left-arrow
            @click-left="onClickLeft"
        />
        <div class="content-box">
            <van-steps v-show="active !== 3" :active="active" class="mysteps" active-color="#1989fa">
                <van-step>选择建群模板</van-step>
                <van-step>选择入群客户</van-step>
                <van-step>设置群聊信息</van-step>
            </van-steps>
            <div v-show="active === 0" class="step step1">
                <van-row class="createtempcon">
                    <van-col span="12">请选择模板建群</van-col>
                    <van-col span="12" style="text-align:right;"><van-button type="info" size="small" @click="createTemp">添加建群模板</van-button></van-col>
                </van-row>
                <div v-show="templates.length === 0" class="tempnone">
                    <img src="../../../assets/img/groupTools/templatenone.png" />
                    <span>当前还没有创建群模板，快去创建吧~</span><br />
                    <span class="tempcreate" @click="createTemp">添加建群模板</span>
                </div>
                <div class="tempcon">
                    <van-radio-group v-model="radio">
                        <div v-for="(item,index) in templates" :key="index" class="tempitem" @click="chooseItem(item.id)">
                            <div class="leftradio">
                                <van-radio :name="item.id"></van-radio>
                            </div>
                            <div class="des">
                                <div class="top">
                                    <div class="topleft">{{item.templateName}}</div>
                                    <div class="topright"><span @click.stop="editItem(item)">编辑</span> <span @click.stop="deleteItem(item)">删除</span></div>
                                </div>
                                <div class="bottom">共{{item.length}}名员工:<span v-for="(item2,index2) in item.qwUserNames.split(',')" :key="index2">{{item2}} </span></div>
                            </div>
                        </div>
                    </van-radio-group>
                </div>
            </div>
            <div v-show="active === 1" class="step step2">
                <div class="contentdes">请选择微信或企业微信客户，邀请客户后将会创建外部群聊。由于企业微信限制，建群时，群聊里最多一次拉入40个人。</div>
                <div class="customlist">

                    <div  class="choosecus" >选择客户</div>
                    <div>
                        <van-search v-model="searchCustomName" placeholder="输入客户昵称进行搜索" @search="onSearchCustom" @input="onSearchCustom" @clear="onSearchCustom" @blur="onSearchCustom" />
                    </div>
                    <div class="all leftall">当前剩余可拉入{{leftLength}}人</div>

                    <van-checkbox-group  v-model="customResult" :max="maxLength - result.length - 1">
                        <div v-for="(item,index) in customList" :key="index" class="customitem" style="overflow:hidden;">
                            <div class="customleft" >
                                <van-image
                                        width="40"
                                        height="40"
                                        :src="item.custAvatar"
                                />
                            </div>
                            <div class="customcenter">{{item.custName}}</div>
                            <div class="customright">@微信</div>
                            <div class="rightcheckbox">
                                <van-checkbox :name="item.customerId"></van-checkbox>
                            </div>
                        </div>
                    </van-checkbox-group>
                    <div v-show="customList.length == 0" class="nomore">暂无结果~</div>
                    <div v-show="customList.length > 0" class="nomore">没有更多了~</div>
                </div>
                <div v-show="customLength == 0" class="nomore">请先在企微中添加客户再进行拉群操作~</div>
                <!--
                <div class="customlist">
                    <div v-for="(item,index) in customResultList" :key="index" class="customitem" style="overflow:hidden;">
                        <div class="customleft" >
                            <van-image
                                    width="40"
                                    height="40"
                                    :src="item.custAvatar"
                            />
                        </div>
                        <div class="customcenter">{{item.custName}}</div>
                        <div class="customright">@微信</div>
                    </div>
                </div>
                -->
            </div>
            <div v-show="active === 2" class="step step3">
                <div>
                    <van-form @submit="onSubmit2">
                        <van-field
                            v-model="formItems2.groupName"
                            label="群名称"
                            placeholder="请设置群名称"
                            maxlength="50"
                            :rules="[{ required: true, message: '' }]"
                        />
                    </van-form>
                </div>
            </div>
            <div v-show="active === 3" class="step step4">
                <div class="contentdes">提示：拉群结束前请不要关闭此窗口</div>
                <ul class="step4ul">
                    <li>将为选择的{{maxLength - leftLength}}个成员一键拉群。</li>
                    <li>因企业微信限制，若客户已不是好友将无法拉入群内哦</li>
                    <li>点击【一键拉群】按钮就可以一键创建群聊哦~</li>
                </ul>
                <div class="groupcon">
                    <div class="left"><img src="../../../assets/img/groupTools/group1.png"  /></div>
                    <div class="center">{{formItems2.groupName}}</div>
                    <div class="right"><van-button class="mybtn" type="info" size="small" :disabled="beenCreated"    @click="createGroup">一键拉群</van-button></div>
                </div>
            </div>
        </div>
        <div v-show="templates.length > 0" class="footer">
            <div v-show=" active === 0" class="bigbtn">
                <van-button :disabled="radio == ''"  type="info" size="small" @click="nextStep">下一步</van-button>
            </div>
            <van-row v-show=" active !== 0 && active !== 3">
                <van-col span="12"><van-button plain hairline type="info" size="small" @click="preStep">上一步</van-button></van-col>
                <van-col v-show="active !== 2" span="12"><van-button plain hairline type="info" size="small" :disabled="active===1 && customResultList.length == 0" @click="nextStep">下一步</van-button></van-col>
                <van-col v-show="active === 2" span="12"><van-button type="info" size="small" :disabled="formItems2.groupName == ''" @click="startGroup">开始拉群</van-button></van-col>
            </van-row>
        </div>
        <van-action-sheet v-model="show" :title="formItems.id?'编辑建群模板':'添加建群模板'">
            <div class="content">
                <div class="contentdes">使用说明：建群模板添加完成后，按照此模板创建的群聊将会自动选择员工，并一键设置群信息和规则</div>
                <div>
                    <van-form @submit="onSubmit">
                        <van-field
                            v-model="formItems.templateName"
                            name="模板名称"
                            label="模板名称"
                            maxlength="23"
                            placeholder="请输入模板名称"
                            :rules="[{ required: true, message: '' }]"
                            @input="unSpace"
                        />
                        <van-field
                                v-model="formItems.qwUserNames"
                                name="选择员工"
                                label="选择员工"
                                placeholder="请选择员工"
                                class="mydisabled"
                                :rules="[{ required: true, message:'' }]"
                                @click="showModel2"
                        />
                        <div class="staffdes van-cell">当前可选择{{staffLength - result.length}}位员工</div>
                        <div style="margin: 16px;">
                            <van-button round block type="info" native-type="submit">{{formItems.id ? '保存' : '创建模板'}}</van-button>
                        </div>
                    </van-form>
                </div>
            </div>
        </van-action-sheet>
        <van-action-sheet v-model="show2" title="选择员工">
            <div class="content2">
                <div class="all">全部员工({{staffLength}})</div>
                <div>
                    <van-search v-model="name" placeholder="请输入搜索关键词" @input="onSearch" @clear="onSearch" @search="onSearch" @blur="onSearch" />
                </div>
                <div class="staffcon">
                    <van-checkbox-group v-model="result">
                        <van-checkbox v-for="(item,index) in staffList" :key="index" :name="item.userId" >
                            <div>
                                <van-image
                                    width="40"
                                    height="40"
                                    :src="item.avatar"
                                />
                                {{item.name}}
                            </div>
                        </van-checkbox>
                    </van-checkbox-group>
                    <div class="nomore">没有更多了~</div>
                </div>
                <div class="confirmbtn">
                    <van-button  block type="info" @click="chooseStaff">确认</van-button>
                </div>
            </div>
        </van-action-sheet>
        <van-action-sheet v-model="show3" title="选择客户">
            <div class="content2">
                <div class="all">当前剩余可拉入{{leftLength}}人</div>
                <div>
                    <van-search v-model="searchCustomName" placeholder="输入客户昵称进行搜索" @search="onSearchCustom" @input="onSearchCustom" @clear="onSearchCustom" @blur="onSearchCustom" />
                </div>
                <div class="staffcon">
                    <van-checkbox-group v-model="customResult" :max="maxLength - result.length - 1">
                        <van-checkbox v-for="(item,index) in customList"  :key="index" :name="item.customerId" >
                            <div>
                                <van-image
                                        width="40"
                                        height="40"
                                        :src="item.custAvatar"
                                />
                                {{item.custName}}
                            </div>
                        </van-checkbox>
                    </van-checkbox-group>
                    <div v-show="customList.length == 0" class="nomore">暂无结果~</div>
                    <div v-show="customList.length > 0" class="nomore">没有更多了~</div>
                </div>
                <div class="confirmbtn">
                    <van-button  block type="info" @click="chooseCustom">确认</van-button>
                </div>
            </div>
        </van-action-sheet>
    </div>
</template>
<script>
import { getFlowList, addTemp, editTemp, getTempList, deleteTemp, getCustomList } from '@/api/modules/groupTools'
import { Toast, Dialog } from 'vant'
import '../constant'
// eslint-disable-next-line no-unused-vars
import store from '@/store/index'
import { mapGetters } from 'vuex'
// eslint-disable-next-line no-unused-vars
import { setUserAuth, getUser } from '@/scripts/userAuth'
// eslint-disable-next-line no-unused-vars
import wxSDK from '@/scripts/wxsdk'
export default {
  name: '',
  components: {},
  data() {
    return {
      inited: false,
      active: 0,
      show: false,
      show2: false,
      show3: false,
      staffList: [],
      // 我最近的联系人
      myCustom: [],
      customList: [],
      // 只加载一次
      beendCount: false,
      // 客户总数
      customLength: 0,
      choosedStaff: [],
      result: [],
      // 最近可选择的人
      myCustomResult: [],
      customResult: [],
      customResultList: [],
      staffLength: '',
      templates: [],
      formItems: {
        id: null,
        creatorId: '',
        templateName: '',
        qwUserIds: '',
        qwUserNames: ''
      },
      formItems2: {
        // 群名称
        groupName: ''
      },
      name: '',
      searchCustomName: '',
      // 最多可拉人
      maxLength: 40,
      // 剩余可拉人
      leftLength: 0,
      radio: '',
      BuserInfo: {
        userId: '',
        tenantId: global.APP_CONFIG.tenantId
      },
      beenCreated: false,
      onloading: false,
      xxxx: 'fffff'
    }
  },
  computed: {
    ...mapGetters({
      'userInfo': 'userAuth/getUsersCurrentInfo'
    })
  },
  watch: {
    customResult: {
      handler(newValue, oldValue) {
        this._countLeftLength()
        this.setCustomResultList()
      },
      deep: true
    }
  },
  async created() {
    if (process.env.NODE_ENV !== 'development') {
      await this.init()
    }
    /*
    if (process.env.NODE_ENV === 'development') {
      this.BuserInfo.userId = '1461239900368314368'
    }*/
    this.getTempList(true)
    this.getFellowList()
    this.getCustomList()
  },
  async mounted() {
    await wxSDK.config([
      'openEnterpriseChat'
    ], [
      'openEnterpriseChat'
    ])
  },
  methods: {
    async init() {
      if (!store.getters['userAuth/getUsersCurrentInfo']) {
        const params = { 'authType': 'webAuthLogin' }
        await setUserAuth(params)
      }
      getUser().then((res) => {
        // eslint-disable-next-line no-empty
        if (res.code !== 0) {
        }
        // eslint-disable-next-line no-empty
        else {

        }
      })
      this.inited = true
    },
    startGroup() {
      this.active = 3
    },
    createGroup() {
      const externalUserIds = []
      this.customResultList.forEach((item) => {
        externalUserIds.push(item.customerId)
      })
      this.onloading = true
      const option = {
        userIds: this.formItems.qwUserIds.replaceAll(',', ';'), // 参与会话的企业成员列表，格式为userid1;userid2;...，用分号隔开。
        externalUserIds: externalUserIds.join(';'), // 参与会话的外部联系人列表，格式为userId1;userId2;…，用分号隔开。
        groupName: this.formItems2.groupName, // 会话名称。单聊时该参数传入空字符串""即可。
        chatId: '', // 若要打开已有会话，需指定此参数。如果是新建会话，chatId必须为空串
        success: function(res) {
          this.beenCreated = true
          this.onloading = false
          Toast('创建成功')
          // this.onloading = false
          // var chatId = res.chatId // 返回当前群聊ID，仅当使用agentConfig注入该接口权限时才返回chatId
        },
        fail: function(res) {
          this.onloading = false
          Toast('创建失败')
          // this.onloading = false
          // alert('创建失败')
        }
      }
      window.wx.openEnterpriseChat(option)
      this.beenCreated = true
    },
    setCustomResultList() {
      const newArr = []
      this.customList.forEach((item) => {
        if (this.customResult.indexOf(item.customerId) >= 0) {
          newArr.push(item)
        }
      })
      this.customResultList = newArr
    },
    chooseItem(id) {
      this.radio = id
    },
    editItem(row) {
      this.show = true
      this.formItems.id = row.id
      this.formItems.templateName = row.templateName
      this.formItems.qwUserIds = row.qwUserIds
      this.formItems.qwUserNames = row.qwUserNames
      this.result = row.qwUserIds.split(',')
    },
    clearData() {
      this.formItems.id = null
      this.formItems.templateName = ''
      this.formItems.qwUserIds = ''
      this.formItems.qwUserNames = ''
      this.result = []
    },
    deleteItem(row) {
      Dialog.confirm({
        title: '删除',
        message: '确认删除这条记录'
      })
        .then(() => {
          deleteTemp(row.id).then((res) => {
            if (res.code === 0) {
              Toast('删除成功')
              this.getTempList()
            }
          })
        })
        .catch(() => {
          // on cancel
        })
    },
    onSearch() {
      this.getFellowList()
    },
    onSearchCustom() {
      this.getCustomList()
    },
    // 获取模板列表
    getTempList(init) {
      getTempList(this.BuserInfo.userId).then(res => {
        if (res.code === 0) {
          res.data.forEach((item) => {
            item.length = item.qwUserIds.split(',').length
          })
          this.templates = res.data
          this.radio = this.templates[0].id
        }
      })
    },
    // 获取客户列表
    getCustomList() {
      const params = {
        tenantId: this.BuserInfo.tenantId
      }
      if (this.searchCustomName) {
        params['custName'] = this.searchCustomName
      }
      getCustomList(params).then(res => {
        if (res.code === 0) {
          if (this.myCustom.length === 0) {
            if (res.data[0]) {
              this.myCustom.push(res.data[0])
            }
            if (res.data[1]) {
              this.myCustom.push(res.data[1])
            }
          }
          this.customList = res.data
          if (this.beendCount === false) {
            this.beendCount = true
            this.customLength = res.data.length
          }
        }
      })
    },
    /*
    // 过滤最近的员工
    filterMyCustom() {
      this.customList.forEach((item, i) => {
        this.myCustom.forEach((item2, j) => {
          if (item2.customerId === item.customerId) { }
        })
      })
    }, */
    // 获取员工列表
    getFellowList() {
      const params = {
        userId: this.BuserInfo.userId
      }
      if (this.name) {
        params['name'] = this.name
      }
      getFlowList(params).then(res => {
        if (res.code === 0) {
          this.staffLength = res.data.length
          this.staffList = res.data
        }
      })
    },
    onClickLeft() {
      this.$router.push({
        path: '/groupTools/' + global.APP_CONFIG.tenantId
      })
    },
    nextStep() {
      if (this.active === 0) {
        this.templates.forEach((item) => {
          if (item.id === this.radio) {
            this.formItems.qwUserIds = item.qwUserIds
            this.result = item.qwUserIds.split(',')
          }
        })
        this._countLeftLength()
      }
      if (this.active < 2) {
        this.active++
      }
    },
    chooseCustom() {
      this.show3 = false
      this.setCustomResultList()
    },
    preStep() {
      if (this.active > 0) {
        this.active--
      }
    },
    unSpace() {
      this.formItems.templateName = this.formItems.templateName.replace(/\s*/g, '')
    },
    createTemp() {
      this.clearData()
      this.show = true
    },
    choosecus() {
      this.searchCustomName = ''
      this.onSearchCustom()
      this.show3 = true
    },
    showModel2() {
      this.name = ''
      this.onSearch()
      this.result = this.formItems.qwUserIds.split(',')
      this.show2 = true
    },
    _countLeftLength() {
      // 最后的 -1 是自己
      this.leftLength = this.maxLength - this.result.length - this.customResult.length - 1
    },
    chooseStaff() {
      if (this.result.length === 0) {
        Toast('请选择员工')
      }
      else {
        this.show2 = false
        this._setSfaff()
        this._countLeftLength()
      }
    },
    _setSfaff() {
      this.choosedStaff = []
      const names = []
      const ids = []
      if (this.result.length > 0) {
        this.staffList.forEach((item) => {
          if (this.result.indexOf(item.userId) >= 0) {
            this.choosedStaff.push(item)
            names.push(item.name)
            ids.push(item.userId)
          }
        })
        this.result = ids
        this.formItems.qwUserIds = ids.join(',')
        this.formItems.qwUserNames = names.join(',')
      }
    },
    onSubmit() {
      const ids = []
      const names = []
      if (this.formItems.id) {
        this.formItems.qwUserIds = this.result.join(',')
        editTemp(this.formItems).then(res => {
          if (res.code === 0) {
            Toast('修改成功')
            this.show = false
            this.getTempList()
          }
        })
      }
      else {
        this.choosedStaff.forEach((item) => {
          ids.push(item.userId)
          names.push(item.name)
        })
        this.formItems.qwUserIds = ids.join(',')
        this.formItems.qwUserNames = names.join(',')
        addTemp(this.formItems).then(res => {
          if (res.code === 0) {
            Toast('创建成功')
            this.show = false
            this.getTempList()
          }
        })
      }
    },
    onSubmit2() {

    }
  }
}

</script>
<style lang="less">
    #app{overflow:auto !important;}
    .step4ul{font-size:12px;
    line-height:22px;margin-bottom:20px;
        margin-left:20px;
    li{
        list-style-type:disc;
    }
    }
    .groupcon{
        font-size:14px;
        overflow:hidden;
        height:40px;
        .left{float:left;margin-right:10px;
        img{width:40px;height:40px;border-radius:3px;}
        }
        .center{width:40%;float:left;line-height:40px;}
        .right{width:30%;float:right;text-align:right;
            .mybtn{margin-top:4px;}
        }
    }
    .mydisabled{
        span{
            color:#323233;
        }
    }
    .mysteps{
        .van-step--horizontal{
            .van-step__icon {
                font-size:0.6rem
            }
        }
        .van-step--finish{
            .van-step__title{color:#1989fa;}
        }
    }
    .content {
        padding: 10px;
        min-height:300px;

    }
    .content2 {
        min-height:300px;
        padding:10px;
        .all {font-size:14px;}
        .van-checkbox-group{
            padding:0px 10px;
            .van-checkbox{margin-bottom:10px;font-size:14px;
                padding:10px 0px;
                line-height:40px;
                border-top:1px solid #efefef;
                border-bottom:1px solid #efefef;
            }
        }
        .staffcon{
            height:150px;overflow:scroll;
            margin-bottom:20px;
        }
        .van-image{
            margin-right:10px;
            img{border-radius:3px;}
        }
    }
</style>
<style lang='less' scoped>
    .leftall{text-align:right;color:#8a8a8a;
        border-bottom: 1px solid #cdcdcd;
        padding-bottom:10px;margin-bottom:10px;
    }
    .nomore{font-size:14px;color:#c2c2c2;text-align:center;}
    .staffdes{font-size:12px;color:#b5b5b5;}
    .customlist{
        font-size:14px;
        margin-bottom:20px;
        .van-image{border-radius:3px;}
        margin-right:20px;
        .customitem{
            line-height:40px;
            .customleft{float:left;margin-right:10px;}
            .customcenter{float:left;margin-right:10px;}
            .customright{float:left;margin-right:10px;color:#11ad11;}
            .rightcheckbox{height:40px;
                .van-checkbox{height:40px;}
            }
        }
    }
    .choosecus{font-size:20px;color:#000000;margin-bottom:20px;text-align:center;}
    .contentdes{
        font-size:14px;
        padding:10px;
        background-color: rgba(255, 251, 231, 1);
        border-color: rgba(253, 226, 211, 1);
        border:1px solid rgba(253, 226, 211, 1);
        border-radius:4px;
        margin-bottom:20px;
    }
    .content-box{
        padding:10px;
    }
    .tempmain{min-height:100vh;background-color:#ffffff;}
    .iconitem{width:80px;height:80px;float:left;margin-top:30px;margin-left:30px;}
    .footer{
        padding-top:10px;
        width:100%;
        text-align:center;
        .van-col{text-align:center;}
        .van-button{width:70%;}
        .bigbtn{
            .van-button{width:90%;}
        }
    }
    .tempnone{
        text-align:center;
        img{width:100%;height:auto;}
        span{font-size:14px;color:#8a8a8a;}
        span.tempcreate{color:#1989fa;}
    }
    .createtempcon{font-size:14px;line-height:30px;margin-bottom:20px;}
    .tempcon{
        height:250px;overflow:auto;
        .tempitem{
            padding:10px 10px;
            margin-bottom:20px;
            border:1px solid #b3dcfd;
            overflow:hidden;
        }
        .des{font-size:14px;width:80%;float:left;
            .top{
                height:30px;line-height:30px;font-size:14px;font-weight:bolder;
                .topleft{width:50%;float:left;white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;}
                .topright{width:50%;float:left;text-align:right;font-size:12px;font-weight:normal;color:#46AAFC;}
            }
            .bottom{
                height:60px;line-height:30px;
                font-size:12px;font-weight:normal;
                display: -webkit-box;

                -webkit-box-orient: vertical;

                -webkit-line-clamp: 2;

                overflow: hidden;
            }
        }
        .leftradio{width:10%;float:left;
        height:90px;
            .van-radio{height:90px;}
        }
    }
</style>
