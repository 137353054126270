import { get, post, del } from '@/api/index'
const pre = '/dapi/fellow-customer-management/'

/**
 * 获取员工列表
 */
export const getFlowList = params => {
  return post(pre + 'v1/fellow/group_fellow_list', params)
}
export const addTemp = (params) => {
  return post(pre + 'v1/qw_group_template/add', params)
}
export const editTemp = (params) => {
  return post(pre + 'v1/qw_group_template/edit', params)
}
export const getTempList = (id) => {
  return get(pre + 'v1/qw_group_template/list')
}
export const deleteTemp = (id) => {
  return del(pre + 'v1/qw_group_template/delete/' + id)
}
export const getCustomList = (params) => {
  return post(pre + 'v1/fellow/group_customer_list', params)
}
